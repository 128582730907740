import { render } from "./ProfileSetting.vue?vue&type=template&id=0ba3e3da"
import script from "./ProfileSetting.vue?vue&type=script&lang=ts"
export * from "./ProfileSetting.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTabs,QTab});
