<template>
  <div class="container-smaller q-py-md">
    <q-form ref="formRef">
      <!-- new password -->
      <div>
        <q-input
          type="password"
          lazy-rules
          :rules="[val => (val && val.length > 0) || 'Please input your password']"
          outlined
          v-model="form.password"
          dense
          placeholder="input your password"
        />
      </div>
      <!-- confirm password -->
      <div>
        <q-input
          type="password"
          lazy-rules
          :rules="[val => (val && val.length > 0 && val === form.password) || 'Please input your password again']"
          outlined
          v-model="form.confirm_password"
          dense
          placeholder="confirm your password"
        />
      </div>

      <div class="q-gutter-sm text-right">
        <q-btn flat color="grey" @click="$goto('home')">Go back</q-btn>
        <q-btn color="primary" @click="onSubmit"> Update password </q-btn>
      </div>
    </q-form>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { QForm } from 'quasar'
import logging from '@/utils/logging'
import { UserModel } from '@/components/user/user-model'
import ProfileMixin from './mixins/ProfileMixin.vue'
@Options({
  components: {},
  directives: { maska },
})
export default class ProfilePasswordForm extends mixins(ProfileMixin) {
  requiredRule = {
    message: 'This field is required',
  }

  form = {
    password: '',
    confirm_password: '',
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo || {}
  }

  async onSubmit() {
    const form = this.$refs.formRef as QForm
    form
      .validate()
      .then((success: boolean) => {
        if (!success) {
          return
        }

        this.updateProfile({ _id: this.userInfo._id, password: this.form.password })
      })
      .catch((error: unknown) => {
        logging.debug(error)
      })
  }
}
</script>
