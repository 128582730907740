
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import WebpushServiceWorker from '@/components/subscription/WebpushServiceWorker.vue'

@Options({
  components: {},
  directives: { maska },
})
export default class NotificationForm extends mixins(WebpushServiceWorker) {
  async onTurnOnNotification() {
    await this.registerServiceWorker()
    this.$q.notify({
      type: 'positive',
      message: 'Done',
    })
  }
}
