<template>
  <div class="container-smaller q-py-md">
    <q-form ref="formRef">
      <!-- Email -->
      <div class="q-mb-md">
        <label class="text-grey-9 text-bold">Profile avatar</label>
        <AvatarUploadForm :defaultText="userInfo.displayName" v-model="form.photoURL" />
      </div>

      <!-- Email -->
      <div class="q-mb-md">
        <label class="text-grey-9 text-bold">Email</label>
        <q-input outlined disable v-model="form.email" dense class="bg-grey-2" />
      </div>

      <!-- Display name -->
      <div>
        <label class="text-grey-9 text-bold">Display name</label>
        <q-input
          lazy-rules
          :rules="[val => (val && val.length > 0) || requiredRule.message]"
          outlined
          v-model="form.displayName"
          dense
          placeholder="display name"
        />
      </div>

      <!-- username -->
      <div>
        <label class="text-grey-9 text-bold">Username</label>
        <q-input
          lazy-rules
          :rules="[val => (val && val.length > 0) || requiredRule.message]"
          outlined
          v-model="form.username"
          dense
          placeholder="username"
        >
          <template v-slot:hint> System is using username for mention purpose </template>
        </q-input>
      </div>

      <div class="q-gutter-sm text-right">
        <q-btn flat color="grey" @click="$goto('home')">Go back</q-btn>
        <q-btn color="primary" @click="onSubmit"> Save setting </q-btn>
      </div>
    </q-form>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { QForm } from 'quasar'
import logging from '@/utils/logging'
import { UserModel } from '@/components/user/user-model'
import ProfileMixin from './mixins/ProfileMixin.vue'
import AvatarUploadForm from '../upload/ui/AvatarUploadForm.vue'

@Options({
  components: { AvatarUploadForm },
  directives: { maska },
})
export default class ProfileBasicForm extends mixins(ProfileMixin) {
  requiredRule = {
    message: 'This field is required',
  }

  form = {
    displayName: '',
    email: '',
    username: '',
    photoURL: '',
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo || {}
  }

  async onSubmit() {
    const form = this.$refs.formRef as QForm
    form
      .validate()
      .then((success: boolean) => {
        if (!success) {
          return
        }
        this.updateProfile(this.form)
      })
      .catch((error: unknown) => {
        logging.debug(error)
      })
  }

  async created() {
    this.form = { ...this.form, ...this.userInfo }
  }
}
</script>
