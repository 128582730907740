import { render } from "./AvatarUploadForm.vue?vue&type=template&id=4e97c48c"
import script from "./AvatarUploadForm.vue?vue&type=script&lang=ts"
export * from "./AvatarUploadForm.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QFile from 'quasar/src/components/file/QFile.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QIcon,QFile});
