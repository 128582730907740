<template>
  <section class="q-py-md q-px-lg row column min-h-full-vh">
    <div class="row no-wrap items-center">
      <div>
        <h1 class="text-h4">Profile setting</h1>
        <p class="text-subtitle2">Your display name, username are shared across all your Workspaces.</p>
      </div>
    </div>

    <q-tabs
      no-caps
      flat
      v-model="activeTab"
      dense
      align="left"
      indicator-color="primary"
      class="bg-grey-2 q-mt-lg"
      :breakpoint="0"
    >
      <q-tab name="basic" label="Basic infomation" />
      <q-tab name="password" label="Change password" />
      <q-tab name="notification" label="Notification" />
    </q-tabs>

    <ProfileBasicForm v-if="activeTab === 'basic'" />
    <ProfilePasswordForm v-if="activeTab === 'password'" />
    <NotificationForm v-if="activeTab === 'notification'" />
  </section>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import ProfileBasicForm from '@/components/account/ProfileBasicForm.vue'
import ProfilePasswordForm from '@/components/account/ProfilePasswordForm.vue'
import NotificationForm from '@/components/account/NotificationForm.vue'

@Options({
  components: { ProfileBasicForm, ProfilePasswordForm, NotificationForm },
  directives: { maska },
})
export default class ProfileSetting extends Vue {
  activeTab = ''

  @Watch('activeTab')
  activeTabChanged() {
    this.$router.push({
      name: 'profile_setting',
      params: { tab: this.activeTab },
    })
  }

  gotoPassword() {
    this.$router.push({
      name: 'profile_setting',
      params: { tab: 'password' },
    })
  }

  created() {
    this.$meta.setMeta({ title: ['Profile setting'] })
    this.activeTab = this.$route.params.tab ? (this.$route.params.tab as string) : 'basic'
  }
}
</script>
