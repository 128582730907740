
import { Options, mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'

import UploadApi from '@/components/upload/upload-api'
import { CustomFieldValue } from '@/utils/types'
import CustomFieldFormMixin from '../../custom-field/fields/CustomFieldFormMixin.vue'
import UploadingProgress from './UploadingProgress.vue'
import UserAvatar from '@/components/user/ui/UserAvatar.vue'

@Options({
  components: { UploadingProgress, UserAvatar },
  directives: { maska },
})
export default class AvatarUploadForm extends mixins(CustomFieldFormMixin) {
  @Prop({ default: 'U' })
  defaultText!: string

  file: File | null = null

  get value() {
    return this.modelValue
  }

  set value(value: CustomFieldValue) {
    this.$emit('update:modelValue', value)
    this.onChange(value)
  }

  @Watch('file')
  async handleUpload() {
    if (!this.file) {
      return false
    }

    const uploaded = await UploadApi.uploadFile(this.file)
    if (uploaded && uploaded.filename) {
      this.value = uploaded.url
    }

    this.file = null
  }
}
