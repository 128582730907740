
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import ProfileBasicForm from '@/components/account/ProfileBasicForm.vue'
import ProfilePasswordForm from '@/components/account/ProfilePasswordForm.vue'
import NotificationForm from '@/components/account/NotificationForm.vue'

@Options({
  components: { ProfileBasicForm, ProfilePasswordForm, NotificationForm },
  directives: { maska },
})
export default class ProfileSetting extends Vue {
  activeTab = ''

  @Watch('activeTab')
  activeTabChanged() {
    this.$router.push({
      name: 'profile_setting',
      params: { tab: this.activeTab },
    })
  }

  gotoPassword() {
    this.$router.push({
      name: 'profile_setting',
      params: { tab: 'password' },
    })
  }

  created() {
    this.$meta.setMeta({ title: ['Profile setting'] })
    this.activeTab = this.$route.params.tab ? (this.$route.params.tab as string) : 'basic'
  }
}
