
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import QForm from 'quasar/src/components/form/QForm.js';
import logging from '@/utils/logging'
import { UserModel } from '@/components/user/user-model'
import ProfileMixin from './mixins/ProfileMixin.vue'
import AvatarUploadForm from '../upload/ui/AvatarUploadForm.vue'

@Options({
  components: { AvatarUploadForm },
  directives: { maska },
})
export default class ProfileBasicForm extends mixins(ProfileMixin) {
  requiredRule = {
    message: 'This field is required',
  }

  form = {
    displayName: '',
    email: '',
    username: '',
    photoURL: '',
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo || {}
  }

  async onSubmit() {
    const form = this.$refs.formRef as QForm
    form
      .validate()
      .then((success: boolean) => {
        if (!success) {
          return
        }
        this.updateProfile(this.form)
      })
      .catch((error: unknown) => {
        logging.debug(error)
      })
  }

  async created() {
    this.form = { ...this.form, ...this.userInfo }
  }
}
